import React from 'react';
import classes from './CarouselScrollLeftButton.module.css'
import Arrow from '../../assets/images/ArrowLeft.png'

const carouselScrollLeftButton = (props) => {
    return ( 
            <div className={classes.CarouselScrollLeftButton}
            onClick={props.action}
            >
                <img className={classes.arrowImage}
                    src = {Arrow}
                    alt = "Left Arrow"
                ></img>
                
            </div>
     );
};
export default carouselScrollLeftButton;