import React from 'react';
import classes from './CarouselScrollRightButton.module.css'
import Arrow from '../../assets/images/ArrowLeft.png'

const carouselScrollRightButton = (props) => {
    
    return ( 
            <div className={classes.CarouselScrollRightButton}
                onClick ={props.action}
            >
                <img className={classes.arrowImage}
                    src = {Arrow}
                    alt = "Left Arrow"
                ></img>
            </div>
     );
};
export default carouselScrollRightButton;