import React from 'react';
import classes from './FeatureBlockDescription.module.css'

const featureBlockDescription = (props) => {

    if (props.color === 'white') { return (

        <div className={classes.FeatureBlockDescriptionWhite}>
        {props.value}
    </div>
    )} else {
    return ( 
            <div className={classes.FeatureBlockDescriptionGrey}>
                {props.value}
            </div>
     );
    };
};
export default featureBlockDescription;