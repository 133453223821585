import React from 'react';
import classes from './Footer.module.css'
import NewsLetterSignup from '../../molecules/NewsletterSignup/NewsLetterSignUp'
import FooterMenu from '../../molecules/FooterMenu/FooterMenu'
import HatchLogo from '../../atoms/HatchLogo/HatchLogo'

const footer = (props) => {
    return ( 
            <div className = {classes.parent}>
            <div className={classes.Footer}>
                <div className={classes.Logo}><HatchLogo></HatchLogo></div>
                <div className={classes.Menu}><FooterMenu></FooterMenu></div>
            </div>
            <p className={classes.copyright}>©2022 Blue Parable Corporation</p>
            </div>
     );
};
export default footer;