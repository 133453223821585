import React from 'react';
import classes from './HomeHeader.module.css'
import HatchLogo from '../../atoms/HatchLogo/HatchLogo'
import HeaderMenu from '../../molecules/HeaderMenu/HeaderMenu'
import ShopifyPartnerLogo from '../../assets/images/ShopifyPlus_Primary_Inverted.svg'
import homePageBanner from '../../assets/images/HomePageBanner.svg'
const header = (props) => {
    return ( 
            <div className={classes.Header}>
                <div className={classes.Logo}>
                <img
                className ={classes.BannerImage}
                src={homePageBanner}
                alt = 'Hatch Logo'
                />

                </div>

                <div className={classes.Menu}>
                <div className= {classes.ImageBox}>
                <img className = {classes.hatchimg}
                src={ShopifyPartnerLogo}
                alt = 'Shopify Partners Logo'
                />

                </div>
                    <HeaderMenu></HeaderMenu></div>
            </div>
     );
};
export default header;