import React from 'react';
import classes from './StepDescription.module.css'

const stepDescription = (props) => {
    return ( 
            <div className={classes.StepDescription}>
                {props.value}
            </div>
     );
};
export default stepDescription;