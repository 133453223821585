import React from 'react';
import classes from './FeatureBlockIcon.module.css'

const featureBlockIcon = (props) => {
    return ( 
            <div className={classes.FeatureBlockIcon}>
                <img className = {classes.hatchimg}
                src={props.icon}
                />
            </div>
     );
};
export default featureBlockIcon;