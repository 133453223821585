import React, { Fragment, Component } from 'react';
import logo from './logo.svg';
import hatchlogo from './hatchlogo.jpg';
import './App.css';
import HatchProductPage from './pages/HatchProductPage/HatchProductPage'; 
import HomePage from './pages/HomePage/HomePage';
import HatchFAQPage from './pages/HatchFAQPage/HatchFAQPage';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as globalActions from './redux_store/actions/_globalActions';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';

class App extends Component {
    componentWillMount(){
      //alert('[App.js].App.componentWillReceiveMount: Begin')
    }
    componentWillReceiveProps(){
      //alert('[App.js].App.componentWillReceiveProps: Begin')
    }
    // componentDidMount() {
    //   alert('[App.js].App.componentDidMount: Begin')

    // }

    render() {
      return (
        //  <HatchProductPage>

        //  </HatchProductPage>
        <div className="App">
            <BrowserRouter>
                        <Routes>
                          <Route path="/" element={<HomePage />}>
                            <Route index element={<HomePage />} />
                          </Route>
                          <Route path="/hatch" element={<HatchProductPage />} />
                          <Route path="/hatch/faq" element={<HatchFAQPage />} />
                          <Route path ="/privacy" element={<PrivacyPolicyPage /> }/>
                        </Routes>
            </BrowserRouter>
            </div>
      );
    };
};

//Map the redux state values to component properties
const mapStateToProps = state => {
  //alert('[App.js]: mapStateToProps')
  return {
      selectedReview: state.global.selectedReview
  };
};

//Map Required Action Dispatchers to component properties
const mapDispatchToProps = dispatch => {
  //alert('[App.js]: mapDispatchToProps')
  return {
      //I'll need this one here soon after I get uncounted variants added in
      //activeTabHandler: (activeTab) => dispatch({ type: actionTypes.ACTIVE_TAB, activeTab: activeTab }),
      changeReview: (direction) => dispatch(globalActions.turnCarousel(direction)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);