import React from 'react';
import classes from './StepIcon.module.css'

const stepIcon = (props) => {
    return ( 
            <div className={classes.StepIcon}>
             <img className = {classes.hatchimg}
                src={props.image}
                />
            </div>
     );
};
export default stepIcon;