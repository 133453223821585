import React from 'react';
import classes from './HeroBanner.module.css'
import HeroImage from '../../assets/images/ana-40.jpg'
import HeroMessage from '../../molecules/HeroMessage/HeroMessage'
import HeroCallToActionButton from '../../atoms/HeroCallToActionButton/HeroCallToActionButton'

const heroBanner = (props) => {
    return ( 
            <div className={classes.HeroBanner}>
            <div className={classes.Background}>
            {/* <div className={classes.Title}>Hero Banner</div> */}
            </div>
            <div className = {classes.HeroImageContainer}>
             <img className = {classes.HeroImage}
                src={HeroImage}
                alt = 'Hatch Logo'
                />
            </div>
            <div className = {classes.RightColumn}>
            <div className = {classes.HeroMessage}> <HeroMessage></HeroMessage></div>
            <div className = {classes.HeroCallToActionButton}> <HeroCallToActionButton></HeroCallToActionButton></div>
           </div>
            </div>
           
     );
};
export default heroBanner;