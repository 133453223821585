import React from 'react';
import classes from './SectionSubTitle.module.css'

const sectionSubTitle = (props) => {
    return ( 
            <div className={classes.SectionSubTitle}>
                {props.value}
            </div>
     );
};
export default sectionSubTitle;