import React from 'react';
import classes from './FAQSection.module.css'
import SectionTitle from '../../atoms/SectionTitle/SectionTitle'
import FrequentlyAskedQuestion from '../../molecules/FrequentlyAskedQuestion/FrequentlyAskedQuestion'
const faqSection = (props) => {
    return ( 
            <div className={classes.FAQSection}>
            <div className={classes.FAQTitle}>
                <SectionTitle 
                value={"Frequently Asked Questions"}
                ></SectionTitle>
            </div>
            <div className={classes.card1}>
            <FrequentlyAskedQuestion
                question={"Can Hatch be used at multiple locations at the same time"}
                answer={"Yes, Hatch can be used at multiple locations at the same time. It can also be used on multiple iPads at the same time if you have a barcode scanner for each iPad."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Does Hatch support mutliple locations?"}
                answer={"Yes. Hatch supports an unlimited number of locations associated with your Shopify store."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Why does the Dashboard load so slowly when opening the app?"}
                answer={"Hatch loads the dashboard a little slow when you first launch because it is grabbing an entire copy of your Shopify inventory. Once you're past the initial load of the dashboard or a large count task, it should be pretty quick from that point forward. We're working on a fix to make this a lot faster in the near future."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"What is a count task?"}
                answer={"A count task is work that is assigned to an employee that targets a specific list of variants to be counted. Hatch enables you to count variants in a count task using your Socket Mobile barcode scanner. This is the fastest way to count your inventory. You can create count tasks that include variants of a certain product type or vendor. Count tasks keep track of what variants you have counted, how many items of a variant you have counted, and the variance between how many items you have counted and how many Shopify has in inventory. Count tasks also enable you to reconcile your Shopify inventory when you discover a variance. Hatch makes it easy to count and reconcile the inventory quantity for each variant. "}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"What is a count cycle?"}
                answer={"Count cycles are a way of breaking up the work of counting your inventory into smaller daily tasks. Instead of trying to count and reconcile all your inventory once a year, count cycles divide up the work so that you count and reconcile a small number of variants each day."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Will Hatch automatically reconcile the inventory that is inaccurate to Shopify?"}
                answer={"When you have counted all items of a variant within a count task, you may find  a variance in the quantity you counted and the quantity in Shopify. Before completing the count task, Hatch requires that you reconcile all variances. When the reconcile button is pressed for a variant, Hatch updates your Shopify inventory. If you reconcile a quantity by mistake, you can easily undo the reconciliation action and Hatch will set the quantity in Shopify back to the original value."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Will Hatch work if my inventory isn't barcoded?"}
                answer={"No, Hatch provides an efficient way to count your inventory using your Socket Mobile barcode scanner. Without barcodes and the barcode scanner, hatch won't be all that helpful to you."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"What version of iPad do I need to use Hatch?"}
                answer={"Hatch will work with any iPad running iOS 15 or higher."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Will Hatch work on my iPhone?"}
                answer={"No, at this time Hatch is only designed to work on iPads."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Can I use Hatch on my desktop computer?"}
                answer={"No, at this time Hatch is only designed to work on iPads."}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"How many staff members can use the app?"}
                answer={"Hatch does not have a limit on number of staff accounts. Add as many as you'd like!"}
            ></FrequentlyAskedQuestion>
            <FrequentlyAskedQuestion
                question={"Do I have to have a socket mobile barcode scanner?"}
                answer={"Yes, Hatch only works with Socket Mobile barcode scanner. Hatch lets you manually type in barcodes, but it isn't very fun or efficient."}
            ></FrequentlyAskedQuestion>

            
            </div>
            
            </div>
     );
};
export default faqSection;