import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';

//Gather Reducers into a rootREducer
import _globalPageReducer from './redux_store/reducers/_globalReducer';
console.log('[index.js]: Import statements ran')

const hatchStore = createStore(
    combineReducers({
        global: _globalPageReducer
    }),
    {}, // initial state
    compose(applyMiddleware(thunk)
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
console.log('[index.js]: Redux store created')
const root = document.createElement('div');
document.body.appendChild(root);
ReactDOM.render(<Provider store={hatchStore}><App /></Provider>, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();