import React from 'react';
import classes from './HowItWorksSection.module.css'
import HowToStepBlock from '../../molecules/HowToStepBlock/HowToStepBlock'
import CountIcon from '../../assets/images/group-8.svg'
import DashboardIcon from '../../assets/images/group-15.svg'
import InventoryIcon from '../../assets/images/group-27.svg'
import SectionTitle from '../../atoms/SectionTitle/SectionTitle'
import SectionSubTitle from '../../atoms/SectionSubTitle/SectionSubTitle'

const howItWorksSection = (props) => {
    return ( 
            <div className={classes.HowItWorksSection}>
            <div className={classes.title}>
                <SectionTitle 
                value="How it works"
                ></SectionTitle>
            
            <div className={classes.description}>
                <SectionSubTitle
                value = "Hatch helps you keep your inventory accurate in as little as 5 minutes a day. Count your store by scanning each item's barcode and then reconcile any discrepencies in your Shopify Inventory."
                ></SectionSubTitle>
            </div>
            </div>
            <div className={classes.card1}>
                <HowToStepBlock 
                    icon={CountIcon}
                    title="CONNECT YOUR STORE"
                    description="
                    Install the hatch app from the Shopify app store and then download the hatch IOS app onto your iPad from the Apple App Store.
                    Login to your store, and pick the location you want to count.
                    "
                >   
                </HowToStepBlock>
            </div>
            <div className={classes.card2}>
                <HowToStepBlock 
                    icon={InventoryIcon}
                    title="ADD YOUR TEAM"
                    description="Add your employees and group them into teams for easy work assignment. Create unique pins for each employee."
                >   
                </HowToStepBlock>
            </div>
            <div className={classes.card3}>
                <HowToStepBlock 
                    icon={DashboardIcon}
                    title="GET COUNTING!"
                    description="Create cycle counts to automatically generate small daily count tasks for each of your employees. Grab your barcode scanner and start scanning items. "
                >   
                </HowToStepBlock>

            </div>
            </div>
     );
};
export default howItWorksSection;