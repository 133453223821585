import React, {Component} from 'react';
import classes from './ReviewCarousel.module.css'
import CarouselScrollRightButton from '../../atoms/CarouselScrollRightButton/CarouselScrollRightButton'
import CarouselScrollLeftButton from '../../atoms/CarouselScrollLeftButton/CarouselScrollLeftButton'
import ReviewAttibution from '../../atoms/ReviewAttribution/ReviewAttribution'
import Image1 from '../../assets/images/pilarReview.webp'
import Image2 from '../../assets/images/tableofshit.jpg'
import Image3 from '../../assets/images/stupid-dresses.webp'

class reviewCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [
                {
                    image: Image1,
                    review: "Hatch has helped me so much! In the past, we had such a hard time keeping our Shopify inventory data correct. There are just so many things that can cause it to get out of sync. Hatch makes it so easy now. My team complete their daily tasks and our inventory is always correct.",
                    attribution: "Ana McConnell | PILAR Boutique | Loveland CO"
                  }
                  // {
                  //   image: Image2,
                  //   review: "I love Hatch! Gone are the days of making an online sale only to find out that I don't have the item in stock. The best app for inventory I've ever used.",
                  //   attribution: "Kali Graff / Vintage Willows / Loveland CO"
                  // },
                  // {
                  //   image: Image3,
                  //   review: "I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch! I love Hatch!",
                  //   attribution: "Sara Seal | Oh Hello"
                  // }
              ],
        
            activeReview: 0
        
        };
        // This binding is necessary to make `this` work in the callback
        this.scrollRight = this.scrollRight.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
      }

    //   handleClick = () => {
    //     this.state.activeReview = this.state.activeReview + 1
    //     console.log('Active Review:', this.state.activeReview);
    //   };

      scrollRight() {
        if (this.state.activeReview + 1 === this.state.reviews.length) {
            var newActiveReview = 0
        } else {
            var newActiveReview = this.state.activeReview + 1
        }
        // this.state.reviews.length + 1
        this.setState(prevState => ({
          activeReview: newActiveReview
        }));
      }

      scrollLeft() {

        if ((this.state.activeReview - 1) === -1) {
            console.log(`Scroll: ${this.state.activeReview - 1}`)
            var newActiveReview = (this.state.reviews.length - 1)
        } else {
            var newActiveReview = this.state.activeReview - 1
        }
        // this.state.reviews.length + 1
        this.setState(prevState => ({
          activeReview: newActiveReview
        }));
      }

    render() {
    return ( 
            <div className={classes.ReviewCarousel}>  
                    <div className = {classes.Review}>
                    <CarouselScrollLeftButton action = {this.scrollLeft}></CarouselScrollLeftButton>
                        <div className = {classes.ReviewText}>
                        {this.state.reviews[this.state.activeReview].review}
                         <ReviewAttibution value = {this.state.reviews[this.state.activeReview].attribution}></ReviewAttibution>
                        </div>
                         <CarouselScrollRightButton action = {this.scrollRight}></CarouselScrollRightButton>
                    </div>
               <div className = {classes.ImageBox}>
                <img className = {classes.Image}
                        src={this.state.reviews[this.state.activeReview].image}
                        alt = ''
                    />
                </div>
                </div>
     );
    };
};
export default reviewCarousel;