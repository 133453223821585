import React from 'react';
import classes from './FeatureSectionOmbre.module.css'
import SectionTitle from '../../atoms/SectionTitle/SectionTitle'
import SectionSubTitle from '../../atoms/SectionSubTitle/SectionSubTitle'
import FeatureBlock from '../../molecules/FeatureBlock/FeatureBlock'
import AccountsIcon from '../../assets/images/accounts-icon.svg'
import AnalyticsIcon from '../../assets/images/analytics-icon.svg'
import ProductImage from '../../assets/images/hatchplaceholdermainfeatuer.png'

const featureSectionOmbre = (props) => {
    return ( 
            <div className={classes.FeatureSectionOmbre}>
            <div className={classes.FeatureTitle}>

                <SectionTitle 
                value={props.title}
                ></SectionTitle>
            
            <div className={classes.FeatureSubtitle}>
                <SectionSubTitle
                value = {props.subtitle}
                ></SectionSubTitle>
            </div>
            </div>
             {/* <div className={classes.title}>FeatureTitle</div> */}
            <div className={classes.card1}>
            <img className = {classes.hatchimg}
                src={props.image}
                // alt = 'Hatch Logo'
                />

            </div>
            <div className={classes.card2}>
            {props.features.map(feature => {
                    return       <FeatureBlock
                    icon = {feature.icon}
                    // {AnalyticsIcon}
                    bodyColor = 'white'
                    titleColor = "white"
                    name = {feature.name}
                    description = {feature.description}
                    // "This is the coolest feature ever. It does this and that and will make you very very happy. Yay rainbows."
                    ></FeatureBlock>
                } )}
            </div>
            </div>
     );
};
export default featureSectionOmbre;