import React from 'react';
import classes from './FrequentlyAskedQuestion.module.css'
import SectionSubTitle from '../../atoms/SectionSubTitle/SectionSubTitle'
import Separator from '../../assets/images/separator.svg';

const frequentlyAskedQuestion = (props) => {
    return ( 
            <div className={classes.FrequentlyAskedQuestion}>
            <div className={classes.FAQSubtitle}>
                <SectionSubTitle
                value = {props.question}
                ></SectionSubTitle>
            </div>
            <div className = {classes.body}>
                {props.answer}
            </div>
            <div className={classes.MenuSeparator}>
            <hr></hr>
            
            {/* <img src={Separator} alt="Menu Separator"/> */}
        </div>
            </div>
     );
};
export default frequentlyAskedQuestion;