import React from 'react';
import classes from './MenuLinkButton.module.css'
import { Link } from "react-router-dom";

const menuLinkButton = (props) => {
    return (
                <Link className={classes.MenuLinkButton} to= {props.value}>
                {props.label}
                </Link>

     );
};
export default menuLinkButton;