import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import classes from './HomePage.module.css'
import HeroBanner from '../../organisms/HeroBanner/HeroBanner'
import HowItWorksSection from '../../organisms/HowItWorksSection/HowItWorksSection'
import FeatureSectionOmbre from '../../organisms/FeatureSectionOmbre/FeatureSectionOmbre'
import FeatureSectionWhite from '../../organisms/FeatureSectionWhite/FeatureSectionWhite'
import HomeHeader from '../../organisms/HomeHeader/HomeHeader'
import Footer from '../../organisms/Footer/Footer'
import ImageOverlay from '../../molecules/ImageOverlay/ImageOverlay'
import ReviewCarousel from '../../organisms/ReviewCarousel/ReviewCarousel'
import FeatureImage2 from '../../assets/images/PilarFeature2.png'
import ProductImage from '../../assets/images/hatchplaceholdermainfeatuer.png'
import DashboardProductImage from '../../assets/images/DashboardProductImage.png'
import ShoesPage from '../../assets/images/ShoesPage.png'
import homePageBanner from '../../assets/images/HomePageBanner.svg'
import * as globalActionTypes from '../../redux_store/actions/_globalActions';

class HomePage extends Component {
 
    render() {
        return ( 
                <div className={classes.HomePage}>
                <div className={classes.itemA}></div>
                <div className={classes.itemB}><HomeHeader></HomeHeader></div>
                <div className = {classes.itemC}>
 
                </div>
                <div className={classes.itemL}><Footer></Footer></div>
                </div>
         );
        };
};

const mapStateToProps = state => {
    return {
        sideMenuBarCollapsed: state.global.sideMenuBarCollapsed,
        helpPanelCollapsed: state.global.helpPanelCollapsed
    };
};

//Map Required Action Dispatchers to component properties
const mapDispatchToProps = dispatch => {
    return {
        //I'll need this one here soon after I get uncounted variants added in
        //activeTabHandler: (activeTab) => dispatch({ type: actionTypes.ACTIVE_TAB, activeTab: activeTab }),


    };
};

//Use react-redux.connect to give the component access to the defined 
//state slice and to pass the dispatchers to redux
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);