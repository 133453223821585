import React from 'react';
import classes from './HeaderMenu.module.css'
import MenuLinkButton from '../../atoms/MenuLinkButton/MenuLinkButton'

const headerMenu = (props) => {
    return ( 
            <div className={classes.HeaderMenu}>
                 {/* <MenuLinkButton label = " "></MenuLinkButton>
                 <MenuLinkButton label = " "></MenuLinkButton> */}
                <MenuLinkButton label = "HOME" value="../"></MenuLinkButton>
                <MenuLinkButton label = "HATCH" value="../hatch"></MenuLinkButton>
                {/* <MenuLinkButton label = "ABOUT US"></MenuLinkButton> */}
                
            </div>
     );
};
export default headerMenu;