import React from 'react';
import classes from './FeatureBlockName.module.css'

const featureBlockName = (props) => {

    if (props.color === 'white') { return (

        <div className={classes.FeatureBlockNameWhite}>
        {props.value}
    </div>
    )} else {
    return ( 
            <div className={classes.FeatureBlockNamePurple}>
                {props.value}
            </div>
     );
    };

};
export default featureBlockName;