import * as globalActions from '../actions/_globalActions'

//Define Initial State of Application
const initialState = {
    selectedReview: 1
};

//Define rootReducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case globalActions.Actions.TURN_CAROUSEL:
            if (action.direction === 'left') {
                return{
                    ...state,
                    selectedReview: state.selectedReview -1,
                }
            } 
            if(action.direction === 'right') {
                return{
                    ...state,
                    selectedReview: state.selectedReview + 1,
                }
            }
            break;
        default:
            return state;

    }
};

export default reducer