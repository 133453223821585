import React from 'react';
import classes from './FooterMenu.module.css'
import MenuLinkButton from '../../atoms/MenuLinkButton/MenuLinkButton'


const FooterMenu = (props) => {
    return ( 
            <div className={classes.FooterMenu}>
                <div className={classes.button}><MenuLinkButton 
                    label="Privacy Policy"
                    value= "../privacy"
                    ></MenuLinkButton></div>
                {/* <div className={classes.button}><MenuLinkButton 
                    label="Activities"
                    ></MenuLinkButton></div>
                <div className={classes.button}><MenuLinkButton 
                    label="Players"
                    ></MenuLinkButton></div> */}

     

            </div>
     );
};
export default FooterMenu;