import React from 'react';
import classes from './ImageOverlay.module.css'
import HatchScreen from '../../assets/images/hatchScreen.png'

const imageOverlay = (props) => {
    return ( 
            <div className={classes.ImageOverlay}>
                  <img className = {classes.hatchimg}
                src={props.image}
                alt = 'Hatch Logo'
                />
            </div>
     );
};
export default imageOverlay;