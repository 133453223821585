import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import classes from './HatchProductPage.module.css'
import HeroBanner from '../../organisms/HeroBanner/HeroBanner'
import HowItWorksSection from '../../organisms/HowItWorksSection/HowItWorksSection'
import FeatureSectionOmbre from '../../organisms/FeatureSectionOmbre/FeatureSectionOmbre'
import FeatureSectionWhite from '../../organisms/FeatureSectionWhite/FeatureSectionWhite'
import Header from '../../organisms/Header/Header'
import Footer from '../../organisms/Footer/Footer'
import ImageOverlay from '../../molecules/ImageOverlay/ImageOverlay'
import ReviewCarousel from '../../organisms/ReviewCarousel/ReviewCarousel'
import FeatureImage2 from '../../assets/images/asset-3@3x.webp'
import ProductImage from '../../assets/images/feature-01@3x.webp'
import DashboardProductImage from '../../assets/images/asset-5@3x.webp'
import ShoesPage from '../../assets/images/asset-4@3x.webp'
import * as globalActionTypes from '../../redux_store/actions/_globalActions';
import FeatureIconPurple from '../../assets/images/analytics-icon-purple.svg'
import FeatureIconWhite from '../../assets/images/analytics-icon.svg'
import ShopifyPartnerLogo from '../../assets/images/ShopifyPartners_Primary.svg'

class HatchProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [{
                    icon: FeatureIconWhite,
                    name: "Count and Reconcile Inventory",
                    description: "Count and update your inventory using your Shopify POS iPad and Socket Mobile Barcode Scanner."
                  },
                  {
                    icon: FeatureIconWhite,
                    name: "Assign Count Tasks to Staff",
                    description: "Break down the big job of counting inventory into small daily tasks using count cycles that assign the work to your staff automatically."
                  },
                  {
                    icon: FeatureIconPurple,
                    name: "Find and Fix Inventory Issues",
                    description: "Detect and fix issues in your inventory including inaccurate quantities, duplicate barcodes, missing barcodes, and missing costs."
                  },
                  {
                    icon: FeatureIconPurple,
                    name: "Real-time Inventory Value",
                    description: "Hatch's Dashboard gives you a real time view of your inventory cost and retail value."
                  },
                  
                  {
                    icon: FeatureIconWhite,
                    name: "Count with Socket Mobile Barcode Scanner",
                    description: "Use the same Socket Mobile Barcode Scanner you use for your Shopify POS. No need to connect a device when switching between Hatch and Shopify POS. No need to buy another scanner."
                  },
                  
                  {
                    icon: FeatureIconWhite,
                    name: "Track Count Cycle progress",
                    description: "Stay on top of your team's work progress with the Hatch Dashboard. See how much work is left, and how many days are left to complete a count."
                  },
                  {
                    icon: FeatureIconPurple,
                    name: "Employees and Teams",
                    description: "Create employee accounts and group them into teams. Create a unique pin for each employee Assign work to employees directly or the team they are a member of."
                  },
                  {
                    icon: FeatureIconPurple,
                    name: "Support for multiple locations",
                    description: "Hatch supports Shopify merchants with multiple locations. Inventory metrics, Count Cycles, and Count Tasks are all tied to a specific location. Switch locations at anytime."
                  }
                
                
                ]
        
        };
        // This binding is necessary to make `this` work in the callback
        
      }

    componentDidMount() {
        
        // alert('[App.js].App.componentDidMount: Begin')
  
      }

    
    render() {
        
    return ( 
            <div className={classes.HatchProductPage}>
            <div className={classes.itemA}><Header></Header></div>
            <div className={classes.itemB}><HeroBanner></HeroBanner></div>
            <div className={classes.itemC}><HowItWorksSection></HowItWorksSection></div>
            <div className={classes.itemD}><FeatureSectionOmbre 
            features = {this.state.features.slice(0,2)}
            title="Connect to your Shopify Store"
            subtitle ="Hatch was built for Shopify merchants. "
            image = {ProductImage}
            ></FeatureSectionOmbre></div>
            <div className = {classes.itemE}>
                <div className = {classes.itemEA}>
                <ImageOverlay image={FeatureImage2}></ImageOverlay>
                </div>
            </div>
            <div className = {classes.itemF}><FeatureSectionWhite
             features = {this.state.features.slice(2,4)}
            >
            </FeatureSectionWhite></div>
            <div className = {classes.itemG}>
            <ImageOverlay image={DashboardProductImage}></ImageOverlay>

            </div>
            <div className = {classes.itemH}><FeatureSectionOmbre
            features = {this.state.features.slice(4,6)}></FeatureSectionOmbre></div>
            <div className = {classes.itemI}>

            <ImageOverlay image={ShoesPage}></ImageOverlay>
            </div>
            <div className = {classes.itemJ}><FeatureSectionWhite
                features = {this.state.features.slice(6,8)}
            >
            </FeatureSectionWhite></div>
            <div className = {classes.itemK}><ReviewCarousel 
            selectedReview = {this.props.selectedReview}
            action = {this.props.reviewCarouselHandler}
            ></ReviewCarousel></div>
            <div className = {classes.itemL}><Footer></Footer></div>
            </div>
     );
    };
};

const mapStateToProps = state => {
    return {
        // selectedReview: state.global.selectedReview
        
    };
};

//Map Required Action Dispatchers to component properties
const mapDispatchToProps = dispatch => {
    return {
        //I'll need this one here soon after I get uncounted variants added in
        // reviewCarouselHandler: (direction) => dispatch({ type: globalActionTypes.turnCarousel, direction: direction }),

    };
};

//Use react-redux.connect to give the component access to the defined 
//state slice and to pass the dispatchers to redux
export default connect(mapStateToProps, mapDispatchToProps)(HatchProductPage);