import React from 'react';
import classes from './Header.module.css'
import HatchLogo from '../../atoms/HatchLogo/HatchLogo'
import HeaderMenu from '../../molecules/HeaderMenu/HeaderMenu'
import ShopifyPartnerLogo from '../../assets/images/ShopifyPartners_Primary.svg'

const header = (props) => {
    return ( 
            <div className={classes.Header}>
                <div className={classes.Logo}><HatchLogo></HatchLogo></div>


                <div className={classes.Menu}>
                <div className= {classes.ImageBox}>
                <img className = {classes.hatchimg}
                src={ShopifyPartnerLogo}
                alt = 'Shopify Partners Logo'
                />

                </div>
                    <HeaderMenu></HeaderMenu></div>
            </div>
     );
};
export default header;