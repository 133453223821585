import React from 'react';
import classes from './HeroMessage.module.css'

const heroMessage = (props) => {
    return ( 
            <div className={classes.HeroMessage}>
                Inventory. Simplified.
            </div>
     );
};
export default heroMessage;