import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import classes from './HatchFAQPage.module.css'
import Header from '../../organisms/Header/Header'
import Footer from '../../organisms/Footer/Footer'
import homePageBanner from '../../assets/images/HomePageBanner.svg'
import FAQSection from '../../organisms/FAQSection/FAQSection'

class HatchFAQPage extends Component {
 
    render() {
        return ( 
                <div className={classes.HatchFAQPage}>
                <div className={classes.itemA}></div>
                <div className={classes.itemB}><Header></Header></div>
                <div className = {classes.itemC}>
                <FAQSection></FAQSection>
                </div>
                <div className={classes.itemL}><Footer></Footer></div>
                </div>
         );
        };
};

const mapStateToProps = state => {
    return {
        sideMenuBarCollapsed: state.global.sideMenuBarCollapsed,
        helpPanelCollapsed: state.global.helpPanelCollapsed
    };
};

//Map Required Action Dispatchers to component properties
const mapDispatchToProps = dispatch => {
    return {
        //I'll need this one here soon after I get uncounted variants added in
        //activeTabHandler: (activeTab) => dispatch({ type: actionTypes.ACTIVE_TAB, activeTab: activeTab }),


    };
};

//Use react-redux.connect to give the component access to the defined 
//state slice and to pass the dispatchers to redux
export default connect(mapStateToProps, mapDispatchToProps)(HatchFAQPage);