
//Define Action Types
export const Actions = {
    TURN_CAROUSEL: "TURN_CAROUSEL",
}

//Define Actions
export const  turnCarousel = direction => ({
    type: Actions.TURN_CAROUSEL,
    direction: direction,
  });