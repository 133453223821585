import React from 'react';
import classes from './FeatureSectionWhite.module.css'
import FeatureBlock from '../../molecules/FeatureBlock/FeatureBlock'
import AccountsIcon from '../../assets/images/accounts-icon-purple.svg'
import AnalyticsIcon from '../../assets/images/analytics-icon-purple.svg'
import ProductImage from '../../assets/images/hatchplaceholdermainfeatuer.png'

const featureSectionWhite = (props) => {
    

     console.log(props.features)
    
    return ( 
            <div className={classes.FeatureSectionWhite}>
         
            <div className={classes.card1b}>
                {props.features.map(feature => {
                    return       <FeatureBlock
                    icon = {feature.icon}
                    // {AnalyticsIcon}
                    bodyColor = 'grey'
                    name = {feature.name}
                    description = {feature.description}
                    // "This is the coolest feature ever. It does this and that and will make you very very happy. Yay rainbows."
                    ></FeatureBlock>
                } )}
         
 
             </div>
             </div>


            // {/* <div className={classes.card2b}>
            // <FeatureBlock
            //     icon = {props.features[1].icon}
            //     // {AccountsIcon}
            //     titleColor = 'purple'
            //     bodyColor = 'grey'
            //     name = {props.features[1].name}
            //     description = {props.features[1].description}
            //     // "This is the coolest feature ever. It does this and that and will make you very very happy. Yay rainbows."
            //     ></FeatureBlock></div> */}
            
     );
};
export default featureSectionWhite;