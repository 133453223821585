import React from 'react';
import classes from './ReviewAttribution.module.css'

const reviewAttribution = (props) => {
    return ( 
            <div className={classes.ReviewAttribution}>
                    {props.value}
            </div>
     );
};
export default reviewAttribution;