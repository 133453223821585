import React from 'react';
import classes from './HeroCallToActionButton.module.css'
import appDownloadIcon from '../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

const heroCallToActionButton = (props) => {
    const handleClick = () => {
        window.open("https://apps.apple.com/us/app/hatch-inventory-management/id1601128602");
      };
    return ( 
        
        <input type="Image" 
            src= {appDownloadIcon} 
            className={classes.HeroCallToActionButton}
            onClick={handleClick}
        >
        </input> 
            
     );
};
export default heroCallToActionButton;