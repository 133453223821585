import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import classes from './PrivacyPolicyPage.module.css'
import HeroBanner from '../../organisms/HeroBanner/HeroBanner'
import HowItWorksSection from '../../organisms/HowItWorksSection/HowItWorksSection'
import FeatureSectionOmbre from '../../organisms/FeatureSectionOmbre/FeatureSectionOmbre'
import FeatureSectionWhite from '../../organisms/FeatureSectionWhite/FeatureSectionWhite'
import Header from '../../organisms/Header/Header'
import HomeHeader from '../../organisms/HomeHeader/HomeHeader'
import Footer from '../../organisms/Footer/Footer'
import ImageOverlay from '../../molecules/ImageOverlay/ImageOverlay'
import ReviewCarousel from '../../organisms/ReviewCarousel/ReviewCarousel'
import FeatureImage2 from '../../assets/images/PilarFeature2.png'
import ProductImage from '../../assets/images/hatchplaceholdermainfeatuer.png'
import DashboardProductImage from '../../assets/images/DashboardProductImage.png'
import ShoesPage from '../../assets/images/ShoesPage.png'
import * as globalActionTypes from '../../redux_store/actions/_globalActions';

class PrivacyPolicyPage extends Component {
 
    render() {
        return (
          <div className={classes.PrivacyPolicyPage}>
            <div className={classes.itemA}>BLUE PARABLE HOME PAGE</div>
            <div className={classes.itemA}>
              <HomeHeader></HomeHeader>
            </div>
            <div className={classes.itemC}>
              <h1>Hatch Privacy Policy</h1>
              <p>
                Hatch "the App" provides Inventory Management tooling "the
                Service" to merchants who use Shopify to power their stores.
                This Privacy Policy describes how personal information is
                collected, used, and shared when you install or use the App in
                connection with your Shopify-supported store.
              </p>
              <h2>Personal Information the App Collects</h2>
              <p>
                When you install the App, we are automatically able to access
                certain types of information from your Shopify account:
              </p>
              <ul>
                <li>
                  Information about the Products you sell in your Shopify store;
                </li>
                <li>
                  Information about your product Inventory, including inventory
                  quantities and inventory locations;
                </li>
                <li>
                  Information about you and others who may access the App on
                  behalf of your store, such as your name, address, email
                  address, phone number, and billing information;
                </li>
              </ul>
              <p>
                We collect personal information directly from the relevant
                individual, through your Shopify account, or using the following
                technologies: "Cookies" are data files that are placed on your
                device or computer and often include an anonymous unique
                identifier. For more information about cookies, and how to
                disable cookies, visit{" "}
                <a href="http://www.allaboutcookies.org">
                  http://www.allaboutcookies.org
                </a>
                . "Log files" track actions occurring on the Site, and collect
                data including your IP address, browser type, Internet service
                provider, referring/exit pages, and date/time stamps. "Web
                beacons," "tags," and "pixels" are electronic files used to
                record information about how you browse the Site.
              </p>
              <h2>How Do We Use Your Personal Information?</h2>
              <p>
                We use the personal information we collect from you and your
                customers in order to provide the Service and to operate the
                App. Additionally, we use this personal information to:
              </p>
              <ul>
                <li>Communicate with you;</li>
                <li>Optimize or improve the App;</li>
                <li>
                  Provide you with information or advertising relating to our
                  products or services.
                </li>
              </ul>
              <p>
                We do not share your Personal Information with third parties.
              </p>
              <p>
                Finally, we may share your Personal Information to comply with
                applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
              </p>
              <h2>Data Retention</h2>
              <p>
                When you place an order through the Site, we will maintain your
                Order Information for our records for 7 years unless and until
                you ask us to delete this information.
              </p>
              <h2>Changes</h2>
              <p>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
              </p>
              <h2>Contact Us</h2>
              <p>
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by e-mail at{" "}
                <a href="mailto:support@blueparable.com">
                  support@blueparable.com
                </a>{" "}
                or by mail using the details provided below:
              </p>
              <p>
                Attn: Data Privacy Officer<br></br>
                Blue Parable Corporation<br></br>
                201 E 4th St, Loveland, CO 80537<br></br>
                        
              </p>
            </div>
            <div>
              <Footer></Footer>
            </div>
          </div>
        );
        };
};

const mapStateToProps = state => {
    return {
        sideMenuBarCollapsed: state.global.sideMenuBarCollapsed,
        helpPanelCollapsed: state.global.helpPanelCollapsed
    };
};

//Map Required Action Dispatchers to component properties
const mapDispatchToProps = dispatch => {
    return {
        //I'll need this one here soon after I get uncounted variants added in
        //activeTabHandler: (activeTab) => dispatch({ type: actionTypes.ACTIVE_TAB, activeTab: activeTab }),


    };
};

//Use react-redux.connect to give the component access to the defined 
//state slice and to pass the dispatchers to redux
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPage);