import React from 'react';
import classes from './HatchLogo.module.css'
import HatchLogo from '../../assets/images/HatchLogo.svg'

const hatchLogo = (props) => {
    return ( 
            <div className={classes.HatchLogo}>
                <img className = {classes.hatchimg}
                src={HatchLogo}
                alt = 'Hatch Logo'
                />

            </div>
     );
};
export default hatchLogo;