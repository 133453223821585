import React from 'react';
import classes from './HowToStepBlock.module.css'
import StepIcon from '../../atoms/StepIcon/StepIcon'
import StepTitle from '../../atoms/StepTitle/StepTitle'
import StepDescription from '../../atoms/StepDescription/StepDescription'

const howToStepBlock = (props) => {
    return ( 
            <div className={classes.HowToStepBlock}>
                <div className={classes.Section1}><StepIcon image={props.icon}></StepIcon></div>
                <div className={classes.Section2}><StepTitle value={props.title}></StepTitle></div>
                <div className={classes.Section3}><StepDescription value={props.description}></StepDescription></div>
            </div>
     );
};
export default howToStepBlock;