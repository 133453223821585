import React from 'react';
import classes from './SectionTitle.module.css'

const sectionTitle = (props) => {
    return ( 
            <div className={classes.SectionTitle}>
                {props.value}
            </div>
     );
};
export default sectionTitle;