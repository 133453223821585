import React from 'react';
import classes from './FeatureBlock.module.css'
import FeatureBlockIcon from '../../atoms/FeatureBlockIcon/FeatureBlockIcon'
import FeatureBlockName from '../../atoms/FeatureBlockName/FeatureBlockName'
import FeatureBlockDescription from '../../atoms/FeatureBlockDescription/FeatureBlockDescription'


const featureBlock = (props) => {
    return ( 
            <div className={classes.FeatureBlock}>
                <div className={classes.row1}><FeatureBlockIcon icon={props.icon}></FeatureBlockIcon></div>
                <div className={classes.row2}><FeatureBlockName value={props.name} color={props.titleColor}></FeatureBlockName></div>
                <div className={classes.row3}><FeatureBlockDescription value={props.description} color={props.bodyColor}></FeatureBlockDescription></div>
            </div>
     );
};
export default featureBlock;